export default {
    " - {stock} in stock": " - {stock} op voorraad",
    "{count} ratings": "{0} 0 beoordelingen|{1} {count} beoordeling|[2,*] {count} beoordelingen",
    "{shop} scores a {average} based on {total} reviews": "{shop} krijgt een {average} op basis van {total} beoordelingen",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> gebasseerd op <span>{count}</span> reviews",
    "Accept terms and conditions on return submit note": "Accepteer de voorwaarden en condities van deze retour.",
    "Account overview": "Account overzicht",
    "Active filters": "Geselecteerde filters",
    "Add new address": "Nieuw adres toevoegen",
    "Add player | Add players": "Voeg speler toe | Voeg spelers toe",
    "Add product": "Voeg product toe",
    "Add set to cart": "Voeg set toe aan winkelwagen",
    "Add to cart": "In winkelwagen",
    "Add.": "Toevoegen",
    "Added to cart": "Toegevoegd aan winkelwagen",
    "Addition": "Toevoeging",
    "Address": "Adres",
    "Addresses": "Adressen",
    "All {label}": "Alle {label}",
    "All about the {name}": "Alles over de {name}",
    "All about this product": "Alles over dit product",
    "All fields must be filled": "Alle velden moeten gevuld zijn.",
    "Already returned": "Al geretourneerd",
    "Alternate Products": "Alternatieven",
    "An error occurred during the payment process, please try again or use another payment method.": "Er is een fout opgetreden tijdens het betalingsproces, probeer het opnieuw of gebruik een andere betaalmethode.",
    "An error occurred while fetching the order. Please try again later.": "Er is een fout opgetreden bij het ophalen van de order. Controleer je gegevens of probeer het later opnieuw.",
    "An error occurred while fetching the return label. Please try again later.": "Er is een fout opgetreden bij het ophalen van het retourlabel. Probeer het later opnieuw.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "Er is een fout opgetreden bij het ophalen van de bestelling. Controleer je gegevens of probeer het later opnieuw.",
    "Apologies, something went wrong processing your return request.": "Excuses, er ging iets mis met het verwerken van je retour aanvraag.",
    "Apply": "Toepassen",
    "As early as possible": "Zo snel mogelijk",
    "Available sizes": "Beschikbare maten",
    "Back": "Terug",
    "Bank": "Bank",
    "Be inspired": "Laat je inspireren",
    "Benefits of an account": "Voordelen van een account",
    "Best regards, {store}": "Met sportieve groet, {store}",
    "Billing address": "Factuur adres",
    "Billing address is different from the address above": "Factuuradres is verschillend van bovenstaand adres",
    "Billing- and/or shipping address is not valid": "Controleer uw adresgegevens",
    "Birthday": "Geboortedatum",
    "By": "By",
    "By initiating a return, you agree to our return policy.": "Door een retour aan te vragen, ga je akkoord met ons retourbeleid.",
    "By {name} on {date}": "Door {name} op {date}",
    "Can't be returned": "Kan niet worden geretourneerd",
    "Cancel": "Annuleer",
    "Cart": "Winkelwagen",
    "Categories": "Categorie\u00ebn",
    "Change delivery address": "Wijzig gegevens",
    "Change password": "Verander je wachtwoord",
    "Check if all players meet the required options.": "Controleer of alle spelers aan de vereiste opties voldoen.",
    "Checkout": "Afrekenen",
    "Choose a delivery method to proceed to the next step": "Kies een verzend optie om naar de volgende stap te gaan",
    "Choose a payment method to complete your order": "Kies een betaalmethode om je bestelling af te ronden",
    "Choose return reason": "Kies retourreden",
    "Choose your": "Kies je",
    "Choose your FREE product": "Kies jouw GRATIS product",
    "Choose your bank": "Kies je bank",
    "Choose your color": "Kies je kleur",
    "Choose your country": "Kies je land",
    "Choose your credit card": "Kies je credit card",
    "Choose your delivery time:": "Kies je bezorgdag:",
    "Choose your personalisation options": "Kies je bedrukking",
    "Choose your printing personalisation options": "Kies je bedrukking",
    "Choose your size": "Kies je maat",
    "Choose your stringing personalisation options": "Bespannen",
    "Choose your {option}": "Kies je {option}",
    "Choose {option}": "Kies je {option}",
    "City": "Plaats",
    "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te vragen",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Click here to follow your order": "Klik hier om je bestelling te volgen",
    "Click {url} to continue shopping": "Klik {url} om door te gaan met winkelen",
    "Closed": "Gesloten",
    "Color": "Kleur",
    "Company": "Bedrijf",
    "Compare": "Vergelijk",
    "Compare - {products}": "Vergelijk - {products}",
    "Compare products": "Vergelijk producten",
    "Compare {productCount} products": "Geen producten | Vergelijk 1 product | Vergelijk {productCount} producten",
    "Complaint is a required field.": "Klacht is een verplicht veld.",
    "Complete order": "Bestellen en betalen",
    "Complete!": "Klaar!",
    "Conditions": "Condities",
    "Confirm new password": "Bevestig nieuw wachtwoord",
    "Confirm password": "Bevestig Wachtwoord",
    "Contact details": "Contactgegevens",
    "Contact us": "Neem contact op met onze klantenservice",
    "Continue": "Doorgaan",
    "Continue shopping": "Verder winkelen",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "We kunnen het saldo van je giftcardcode niet ophalen. Probeer het later nog eens of neem contact op met onze klantenservice.",
    "Country": "Land",
    "Create account": "Account aanmaken",
    "Create an account": "Account aanmaken",
    "Create an account for your next visit!": "Maak een account aan voor je volgende bezoek!",
    "Created by": "Realisatie webshop",
    "Credit card": "Credit card",
    "Current password": "Huidige wachtwoord",
    "DD": "DD",
    "Date": "Datum",
    "Date of birth": "Geboortedatum",
    "Default billing address": "Standaard Factuuradres",
    "Default shipping address": "Standaard verzendadres",
    "Delete account": "Verwijder account",
    "Delivery costs": "Verzending",
    "Desired delivery day": "Gewenste leverdag",
    "Did you mean {email}?": "Bedoelde je {email}?",
    "Discount": "Je bespaart",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Heb je al een account bij ons? Log dan in {action} zodat we je gegevens op kunnen halen.",
    "Do you have a comment for this return?": "Wil je een opmerking bij deze retour plaatsen?",
    "Do you have any questions?": "Heb je nog vragen?",
    "Do you receive our newsletter?": "Ontvang jij onze nieuwsbrief?",
    "Do you want to merge your old and new shopping basket?": "Wilt je jouw oude en nieuwe winkelmandje samenvoegen",
    "Download invoice": "Download factuur",
    "Download my invoice": "Mijn factuur downloaden",
    "Download retour label": "Retourlabel downloaden",
    "Download return label": "Download retourlabel",
    "E-mail addresses do not match.": "E-mailadressen komen niet overeen.",
    "Edit": "Wijzig",
    "Edit address": "Adres wijzigen",
    "Edit personal settings": "Wijzig persoonlijke instellingen",
    "Email address": "E-mailadres",
    "Enter a correct value": "Voer een correcte waarde in",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Voer je giftcardnummer en e-mailadres in, jouw nieuwe giftcard zal naar dit e-mailadres worden verzonden.",
    "Enter your name": "Voer je naam in",
    "Enter your postal code": "Voer je postcode in",
    "Expected delivery: {date}": "Verwachte leverdatum {date} werkdagen",
    "Extra Addresses": "Extra adres",
    "Featured": "Aanbevolen",
    "Female": "Vrouw",
    "Filter": "Filter",
    "Finalize return": "Retour afronden",
    "First name": "Voornaam",
    "Forgot password": "Wachtwoord vergeten",
    "Forgot password?": "Wachtwoord vergeten?",
    "Free": "Gratis",
    "Friday": "vrijdag",
    "Gewicht": "Gewicht",
    "Giftcard": "Giftcard",
    "Giftcard has already been redeemed.": "Je ingegeven giftcardcode is reeds gebruikt.",
    "Giftcard has been blocked.": "Je ingegeven giftcardcode is geblokkeerd. Controleer of je de giftcard juist hebt ingevuld. Lukt dit alsnog niet? Stuur dan de code van de giftcard en het openstaande bedrag met de melding dat deze is geblokkeerd naar de klantenservice. Wij lossen dit voor je op.",
    "Giftcard has expired.": "Je ingegeven gitcardcode is verlopen.",
    "Go to cart": "Ga naar winkelmandje",
    "Grand Total": "Totaalprijs",
    "Grand total": "Totaal",
    "House no.": "Huisnummer",
    "House number": "Huisnummer",
    "How does it work?": "Hoe werkt het?",
    "I accept the Riverty {url} *": "Ik accepteer de Riverty {url} *",
    "I have a discount code": "Ik heb een kortingscode",
    "I want to add a comment": "Ik wil een opmerking bij mijn order plaatsen",
    "I want to return my products": "Ik wil mijn producten retourneren",
    "I would like to:": "Ik wil graag:",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Als er een account is gevonden met {email}, zul je een e-mail ontvangen met een link om je wachtwoord te resetten.",
    "If you don't already have an account, you can register one {here}": "Als je nog geen account hebt, kun je er {here} een registreren",
    "Instructions": "Instructies",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het juist is ingevuld?",
    "Invalid re-captcha.": "Ongeldige re-captcha.",
    "Item": "Item",
    "Items ordered": "Bestelde producten",
    "Last name": "Achternaam",
    "Latest releases": "Nieuwste releases",
    "Less options": "Minder",
    "Loading shopping cart items...": "Je winkelmandje wordt geladen",
    "Loading totals summary...": "Een ogenblik geduld...",
    "Log in": "Log in",
    "Login": "Login",
    "Login with your account": "Login met je account",
    "Logout": "Uitloggen",
    "MM": "MM",
    "Male": "Man",
    "Max {count} characters": "Maximaal {count} karakters",
    "Message": "Bericht",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "De minimumlengte van dit veld moet gelijk zijn aan of groter zijn dan {length} tekens. Voor- en achterliggende spaties worden genegeerd.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "Het wachtwoord moet minstens {count} verschillende klassen van tekens bevatten. Klassen van tekens: Kleine letters, Hoofdletters, Cijfers, Speciale tekens.",
    "Monday": "maandag",
    "More": "Meer",
    "More options": "Meer",
    "More results for {inputQuery}": "Meer resultaten voor {inputQuery}",
    "Mrs.": "Mevrouw",
    "My account": "Accountoverzicht",
    "My orders": "Mijn orders",
    "My returns": "Mijn retouren",
    "My reviews": "Mijn reviews",
    "My wishlist": "Mijn verlanglijstje",
    "Name": "Naam",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "New password": "Nieuw wachtwoord",
    "Newsletter": "Nieuwsbrief",
    "Newsletters": "Nieuwsbrief",
    "Next": "Volgende",
    "Nice to add": "Leuk om toe te voegen",
    "No addresses available": "Geen adres beschikbaar",
    "No default billing address available": "Er is geen standaard factuuradres beschikbaar",
    "No default shipping address available": "Er is geen standaard verzendadres beschikbaar",
    "No image chosen": "Geen afbeeldingen geslecteerd",
    "No order found with order id {orderId}": "Geen bestelling gevonden met ordernummer {orderId}",
    "No products": "Geen producten",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "No results found for {inputQuery}": "Geen resultaten gevonden voor {inputQuery}",
    "No. Only continue with newly added items": "Nee. Alleen doorgaan met nieuw toegevoegde artikelen",
    "Not all sizes are selected.": "Niet alle maten zijn geselecteerd.",
    "Notify me when my size is back in stock": "Laat me weten wanneer mijn maat weer op voorraad is",
    "One or more products are out of stock.": "E\u00e9n of meerdere producten zijn niet meer op voorraad",
    "Only {qty} available!": "Nog maar {qty} beschikbaar!",
    "Oops, something went wrong": "Oeps, er is iets misgegaan",
    "Opening hours": "Openingstijden",
    "Order": "Order",
    "Order #": "Order #",
    "Order {increment}": "Order {increment}",
    "Order Date:": "orderdatum:",
    "Order comments": "Order berichten",
    "Order for your team": "Bestel voor je team",
    "Order number": "Bestelnummer",
    "Order placed on": "Bestelling geplaatst op",
    "Order this product for your entire team!": "Bestel dit product voor je hele team!",
    "Order this set for your entire team!": "Bestel deze set voor je hele team!",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Voor 22:00, <span>de volgende dag gratis geleverd</span>",
    "Ordered items": "Bestelde artikelen",
    "Other sports": "Andere sporten",
    "Out of stock notification": "Out of stock melding",
    "Overview of your order": "Overzicht van je order",
    "Password": "Wachtwoord",
    "Pay safe": "Veilig betalen",
    "Payment": "Betaling",
    "Payment method": "Betaalmethode",
    "Payment methods": "Betaalmethodes",
    "Personal settings": "Persoonlijke instellingen",
    "Personalisation Title": "Product personaliseren",
    "Personalise product with printing": "Bedrukken",
    "Personalize product": "Bedrukken",
    "Personalize product with name and/or number": "Laat dit product bedrukken met een naam en/of nummer!",
    "Personalize product with stringing": "Mijn racket professioneel laten bespannen",
    "Personalize product with stringing and/or weight": "Kies jouw gewenste snaar en gewicht",
    "Phone number": "Telefoonnummer",
    "Pick up your order at:": "Je kunt jouw order ophalen op de volgende locatie:",
    "Pickup point": "Pickup point",
    "Player": "Speler",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Neem contact op met onze klantenservice voor hulp via info@sportshop.com of op nummer 0416-652803.",
    "Please enter a valid code": "Voer een geldige coupon code in",
    "Please enter a valid quantity.": "Voer een geldige hoeveelheid in.",
    "Please provide a valid email address.": "Geef een geldig e-mailadres op.",
    "Please select a size.": "Selecteer een maat.",
    "Please wait, we are loading the stores ...": "Even geduld, we zijn PostNL-ophaalpunten aan het laden ...",
    "Please wait, your return request is being processed.": "Even geduld, je retour aanvraag wordt verwerkt.",
    "Postal code": "Postcode",
    "Postal code + house number": "Postcode + Huisnummer",
    "Prefix": "Aanhef",
    "Previous": "Vorige",
    "Previous step": "Vorige stap",
    "Previously viewed by you": "Eerder bekeken producten",
    "Price": "Prijs",
    "Price (Ascending)": "Prijs (laag-hoog)",
    "Price (Descending)": "Prijs (hoog-laag)",
    "Print return ticket": "Print retour ticket",
    "Product": "Producten",
    "Product Description": "Omschrijving",
    "Product out of stock": "Product is uitverkocht",
    "Products by this post": "Producten in deze post",
    "Qty": "Aantal",
    "Quantity": "Aantal",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Algemene beoordeling van <span>&rdquo;{name}&ldquo;</span>",
    "Read more": "Lees meer",
    "Read our return policy": "Lees ons retourbeleid",
    "Recent Blog Posts": "Recente Blog Posts",
    "Recent orders": "Recente orders",
    "Recommended": "Aanbevolen",
    "Redeem giftcard": "Giftcard inwisselen",
    "Related Products": "Handig voor erbij",
    "Remove": "Verwijder",
    "Remove from cart": "Verwijder uit winkelmandje",
    "Remove product": "Verwijder product",
    "Repeat email address": "Herhaal je e-mailadres",
    "Request password": "Wachtwoord aanvragen",
    "Request return": "Retour aanvragen",
    "Reset password": "Wachtwoord opnieuw instellen",
    "Return": "Retour",
    "Return my products": "Mijn producten retourneren",
    "Returns": "Retourneren",
    "Reviews": "Reviews",
    "SKU": "SKU",
    "Saturday": "zaterdag",
    "Save": "Opslaan",
    "Save address": "Adres opslaan",
    "Search": "Zoeken",
    "Search for...": "Zoek naar...",
    "Search results": "Zoekresultaten",
    "Search results for &ldquo;{query}&rdquo;": "Zoekresultaten voor &ldquo;{query}&rdquo;",
    "Search your orders": "Zoek je bestelling",
    "Select a country": "Selecteer een land",
    "Select a {carrier} pick-up point": "Selecteer een {carrier} PostNL-ophaalpunt",
    "Select an option": "Selecteer een optie",
    "Select one of the required options.": "Selecteer een van de vereiste opties.",
    "Select your address": "Selecteer jouw adres",
    "Send": "Verstuur",
    "Ship to": "Verzend naar",
    "Shipping": "Verzending",
    "Shipping address": "Adresgegevens",
    "Shipping method": "Verzendmethode",
    "Shipping methods": "Verzendopties",
    "Shopping cart": "Winkelmandje",
    "Short description of your review": "Korte omschrijving van je review",
    "Show differences": "Toon verschillen",
    "Show more": "Toon meer",
    "Show results": "Resultaten tonen",
    "Show {totalItems} results": "Geen resultaten | Toon 1 resultaat | Toon {totalItems} resultaten",
    "Sign me up for the newsletter": "Schrijf mij in voor de nieuwsbrief",
    "Sign up": "Aanmelding",
    "Sir.": "Meneer",
    "Size": "Maat",
    "Size Advice": "Maatadvies",
    "Size Chart": "Maattabel",
    "Sizes": "Maten",
    "Snaar": "Snaar",
    "Sold out": "Uitverkocht",
    "Sold out!": "Dit artikel is helaas uitverkocht",
    "Sold out! Will be expected at <span>{date}</span>.": "Tijdelijk uitverkocht! We verwachten dit product op <span>{date}.</span>",
    "Some items in your shopping cart are out of stock. {action}": "Somige producten uit je winkelwagen zijn helaas niet meer op voorraad. {action}",
    "Something went wrong while restoring your password": "Er ging iets mis tijdens het herstellen van je wachtwoord",
    "Something went wrong, contact us for help.": "Er ging iets mis, neem contact op voor hulp.",
    "Something went wrong, please try again later": "Er ging iets mis, probeer het later nog eens",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "De betaling kon niet worden voltooid. Probeer het opnieuw of kies een andere betaalmethode.",
    "Sorry we're down for maintenance": "Excuses, onze shop is even offline wegens onderhoud",
    "Sort by": "Sorteer op",
    "Specifications": "Kenmerken",
    "Status": "Status",
    "Store": "Winkel",
    "Street": "Straat",
    "Submit": "Verstuur",
    "Submit complaint": "Klacht toevoegen",
    "Submit return": "Retour toevoegen",
    "Submit review": "Verstuur review",
    "Subscribe": "Schrijf je in",
    "Subscribe to our newsletter": "Schrijf je in voor onze nieuwsbrief",
    "Subtotal": "Subtotaal",
    "Successfully created an account, you can log in with your new credentials": "Je account is succesvol aangemaakt, je kunt vanaf nu inloggen met je gegevens",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Je nieuwe giftcard succesvol aangevraagd. Je ontvangt nieuwe giftcard per e-mail.",
    "Successfully saved address": "Adres opgeslagen",
    "Suggested price": "Adviesprijs",
    "Sunday": "zondag",
    "Swipe for more products": "Swipe voor meer producten",
    "Tags": "Tags",
    "Telephone": "Telefoonnummer",
    "Thank you for your order!": "Bedankt voor je order!",
    "Thank you for your order, {name}": "Bedankt voor je bestelling, {name}!",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Bedankt voor je aanvraag, je wordt per e-mail op de hoogte gebracht wanneer de maat weer op voorraad is!",
    "Thank you. We have received your complaint!": "Bedankt, je klacht is in goede orde ontvangen en zal door ons in behandeling worden genomen.",
    "Thanks": "Bedankt",
    "Thanks for placing a review!": "Bedankt voor het achterlaten van een review!",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "De combinatie van het e-mailadres en het wachtwoord is niet bij ons bekend.",
    "The benefits of an account:": "De voordelen van een account:",
    "The cache could not be cleared": "De cache kon niet geleegd worden",
    "The cache of {cache} has been emptied": "De cache van {cache} is succesvol geleegd",
    "The coupon code could not be cancelled, please try again later.": "De coupon code kon niet worden geannuleerd, probeer het later opnieuw.",
    "The coupon code isn't valid. Verify the code and try again.": "Couponcode niet geldig. Heb je een waardebon of giftcard? Klik dan giftcard aan bij betaalmethoden.",
    "The day of birth is required.": "De geboortedag is verplicht.",
    "The day of birth must be a number.": "De geboortedag moet een getal zijn.",
    "The day of birth must be at least 1.": "De geboortedag moet minstens 1 zijn.",
    "The email address is required.": "Het e-mailadres is verplicht.",
    "The email address must not exceed 255 characters.": "Het e-mailadres mag niet langer zijn dan 255 tekens.",
    "The first name is required.": "De voornaam is verplicht.",
    "The first name must be a valid string.": "De voornaam moet een geldige string zijn.",
    "The first name must be at least 2 characters long.": "De voornaam moet minstens 2 tekens lang zijn.",
    "The first name must not exceed 255 characters.": "De voornaam mag niet langer zijn dan 255 tekens.",
    "The gender must be either Male or Female.": "Het geslacht moet mannelijk of vrouwelijk zijn.",
    "The last name is required.": "De achternaam is verplicht.",
    "The last name must be a valid string.": "De achternaam moet een geldige string zijn.",
    "The last name must be at least 2 characters long.": "De achternaam moet minstens 2 tekens lang zijn.",
    "The last name must not exceed 255 characters.": "De achternaam mag niet langer zijn dan 255 tekens.",
    "The month of birth is required.": "De geboortemaand is verplicht.",
    "The month of birth must be a number.": "De geboortemaand moet een getal zijn.",
    "The month of birth must be at least 1.": "De geboortemaand moet minstens 1 zijn.",
    "The most used payment method in The Netherlands": "Meest gebruikte betaalmethode in Nederland",
    "The password confirmation does not match.": "De wachtwoordbevestiging komt niet overeen.",
    "The password is required.": "Het wachtwoord is verplicht.",
    "The payment is canceled, the order is not paid": "De betaling is geannuleerd, de order is niet betaald",
    "The payment provider rejected the payment request, please use another payment method.": "De betaling is afgewezen door de betaalprovider, probeer een andere betaalmethode.",
    "The quantity exceeds the available stock.": "De hoeveelheid overschrijdt de beschikbare voorraad.",
    "The year of birth is required.": "Het geboortejaar is verplicht.",
    "The year of birth must be a number.": "Het geboortejaar moet een getal zijn.",
    "The year of birth must be at least 4 digits long.": "Het geboortejaar moet minstens 4 cijfers lang zijn.",
    "There are no products in your wishlist": "Er zitten geen producten in je verlanglijstje",
    "There are no specification found to compare.": "Er zijn geen specificaties gevonden om te vergelijken.",
    "This is a secure connection": "Dit is een beveiligde verbinding",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Deze persoonlijke pagina biedt je een overzicht van de meest recente activiteiten. Je kunt onder andere bestellingen bekijken en account informatie bijwerken!",
    "This product has custom personalisation options.": "Dit product heeft aangepaste personalisatie-opties.",
    "This product is in stock": "Dit product is op voorraad",
    "This quantity cannot be changed.": "Deze hoeveelheid kan niet worden gewijzigd.",
    "Thursday": "donderdag",
    "Tierprices": "Staffelprijzen",
    "To Kiyoh reviews page": "Geef je mening",
    "To checkout": "Ik ga bestellen",
    "To shipping": "Naar verzending",
    "To shopping cart": "Naar winkelwagen",
    "Total": "Totaal",
    "Track my order": "Mijn bestelling traceren",
    "Tuesday": "dinsdag",
    "Unable to collect giftcard balance.": "We kunnen het saldo van je giftcardcode niet ophalen. Probeer het later nog eens of neem contact op met onze klantenservice.",
    "Unable to save review": "Kan de review niet plaatsen",
    "Upload image": "Upload afbeelding",
    "Used coupon": "Gebruikte kortingscode",
    "View order": "Bekijk order",
    "View product": "Bekijk product",
    "View products.": "bekijk producten.",
    "Visit our <a href=\"/contact\"> customer service </a>": "Bezoek onze <a href=\"/klantenservice\">klantenservice</a>",
    "We expect to deliver on": "We verwachten te bezorgen op",
    "We expect to deliver your complete order on {date}": "We verwachten je volledige order te leveren op {date}",
    "We have found the following address": "We hebben het volgende adres gevonden",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "We raden u aan <strong>\u00e9\u00e9n maat groter</strong> te bestellen dan je normaal draagt.",
    "We still have these colors for size {size} in stock!": "We hebben deze kleur nog op voorraad voor maat {size}! | We hebben deze kleuren nog op voorraad voor maat {size}!",
    "We will send a confirmation by e-mail to": "We sturen een bevestiging per e-mail naar",
    "We'll be back shortly": "We hopen zo snel mogelijk weer online te zijn",
    "Wednesday": "woensdag",
    "Welcome {name}": "Welkom {name}",
    "What do our customers say": "Wat zeggen onze klanten",
    "Why?": "Waarom?",
    "Will be expected at <span>{date}</span>.": "We verwachten dit product op <span>{date}.</span>",
    "Wishlist": "Verlanglijstje",
    "Without extra fees": "Zonder extra kosten",
    "Write a review": "Schrijf een review",
    "Write your comment": "Schrijf je opmerking",
    "Written by {name} on {date}": "Door {name} op {date}",
    "YYYY": "YYYY",
    "Yes, I want to permanently delete my account": "Ja, ik wil mijn account permanent verwijderen",
    "Yes, I\u2019ve double-checked that my email address is correct.": "Ja, ik heb gecontroleerd dat mijn e-mailadres correct is.",
    "Yes. Merge and continue.": "Ja, winkelwagens samenvoegen",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "Je hebt nog producten in je winkelwagen liggen van je vorige bezoek aan onze shop. Wil je deze producten samenvoegen met je nieuwe order?",
    "You already have an account with us. Please log in or continue without an account.": "Je hebt al een account bij ons. Log in of ga door zonder account.",
    "You are now logged out and will return to our home page within 5 seconds.": "U bent nu uitgelogd en zult binnen 5 seconden terugkeren naar de homepagina.",
    "You are now signed out": "Je bent nu uitgelogd",
    "You are subscribed to our newsletter": "Je bent ingeschreven voor onze nieuwsbrief",
    "You can compare up to {count} products": "Je kunt maximaal {count} producten vergelijken",
    "You can create an account after you\u2019ve purchased the order.": "Je kunt een account maken na het afrekenen.",
    "You can only compare up to {products} products at the same time.": "Je kunt maximaal {products} producten tegelijk vergelijken.",
    "You can pick up your order at": "Je kunt je order ophalen op",
    "You don't seem to be logged in, please login to enable stock notifications.": "Je lijkt niet ingelogd te zijn, gelieve in te loggen om de voorraad meldingen te activeren.",
    "You have an account. Would you like to {action}?": "Je hebt al een account, wil je {action}?",
    "You have no product(s) in your shopping cart.": "Je hebt geen producten in je winkelmandje",
    "You have not placed any orders yet": "Je hebt nog geen order bij ons geplaatst",
    "You have not reviewed any products yet": "Je hebt nog geen product reviews achter gelaten",
    "You may also like this": "Dit vind je misschien ook leuk",
    "You will receive a confirmation via": "Je ontvangt een bevestiging via",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Je ontvangt binnen 15 minuten een e-mail ter bevestiging van jouw bestelling met daarin alle details. Controleer je spam-map als je na 15 minuten nog geen bevestiging e-mail hebt ontvangen. Als je vragen hebt over de status van jouw bestelling, kun je het beste contact opnemen met de klantenservice.",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "Je ontvangt spoedig een e-mail met de details van je bestelling. Voor vragen over je bestelling bezoek de <a href=\"/klantenservice\">klantenservice</a> en neem contact op!",
    "Your applied coupon code is {couponCodeInput}.": "Je ingevoerde kortingscode is {couponCodeInput}.",
    "Your compare list is empty.": "Je vergelijk lijst is leeg.",
    "Your email address": "Je e-mailadres",
    "Your last chosen payment method": "Jouw laatst gekozen betaal methode",
    "Your message has been sent.": "Je bericht is verstuurd, we nemen zo snel mogelijk contact met je op.",
    "Your order has been placed successfully.": "Jouw order is succesvol geplaatst.",
    "Your order number is: <strong>#{orderid}</strong>": "Je bestelnummer is: <strong>#{orderid}</strong>",
    "Your order will be delivered to": "Je order zal verstuurd worden naar",
    "Your ordernumber is: #{order_id}.": "Je order nummer is: #{order_id}.",
    "Your password has been updated": "Uw wachtwoord is bijgewerkt",
    "Your rating": "Jouw beoordeling",
    "Your recommended size is currently not available!": "Jouw aanbevolen maat is momenteel niet beschikbaar!",
    "contact_form.subject": "{name} heeft het contactformulier op {store}Name ingevuld.",
    "description": "Omschrijving",
    "here": "hier",
    "in {label}": "in {label}",
    "login": "lnloggen",
    "name": "Naam",
    "new": "Nieuw",
    "ordered": "Besteld",
    "personal ID": "personal ID",
    "recommended": "Ons advies",
    "review": "review",
    "subscribe": "Schrijf je in",
    "terms of payment": "betalingsvoorwaarden",
    "to": "tot",
    "tomorrow": "morgen",
    "validation.email": "Ongeldig e-mailadres voor veld '{attribute}'",
    "validation.required": "Veld '{attribute}' is verplicht",
    "{count} results": "Geen resultaten | 1 resultaat | {count} resultaten",
    "{distance} kilometer": "{distance} kilometer",
    "{distance} meter": "{distance} meter",
    "{name} has been added to your cart.": "{name} is toegevoegd aan je winkelwagen.",
    "{qty} pieces for {price} each": "{qty} stuks voor {price} per stuk"
}