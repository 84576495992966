export default {
    " - {stock} in stock": " - {stock} auf Lager",
    "{count} ratings": "{0} 0 Bewertungen|{1} {count} Bewertung|[2,*] {count} Bewertungen",
    "{shop} scores a {average} based on {total} reviews": "{shop} ist eine {im} Durchschnitt basierend auf {average} gesamt",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> basierend auf <span>{count}</span> Bewertungen",
    "Accept terms and conditions on return submit note": "AGBs zur R\u00fccksendung akzeptieren.",
    "Account overview": "Konto-\u00dcbersicht",
    "Active filters": "Aktive Filter",
    "Add new address": "Neue Adresse hinzuf\u00fcgen",
    "Add player | Add players": "Spieler hinzuf\u00fcgen | Spieler hinzuf\u00fcgen",
    "Add product": "Produkt hinzuf\u00fcgen",
    "Add set to cart": "Set in den Warenkorb",
    "Add to cart": "In den Warenkorb",
    "Add.": "Zusatz",
    "Added to cart": "Zum Warenkorb hinzugef\u00fcgt",
    "Addition": "Zusatz",
    "Address": "Adresse",
    "Addresses": "Adressen",
    "All {label}": "Alle {label}",
    "All about the {name}": "\u00dcber {name}",
    "All about this product": "\u00dcber dieses Produkt",
    "All fields must be filled": "Alle Felder m\u00fcssen ausgef\u00fcllt werden.",
    "Already returned": "bereits zur\u00fcckgesendet",
    "Alternate Products": "Alternative Produkte",
    "An error occurred during the payment process, please try again or use another payment method.": "W\u00e4hrend des Zahlungsvorgangs ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder verwenden Sie eine andere Zahlungsmethode.",
    "An error occurred while fetching the order. Please try again later.": "Beim Abrufen der Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie es sp\u00e4ter erneut.",
    "An error occurred while fetching the return label. Please try again later.": "Beim Abrufen des R\u00fccksendeetiketts ist ein Fehler aufgetreten. Bitte versuchen Sie es sp\u00e4ter erneut.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "Beim Abrufen der Bestellung ist ein Fehler aufgetreten. Bitte \u00fcberpr\u00fcfen Sie Ihre Angaben oder versuchen Sie es sp\u00e4ter erneut.",
    "Apologies, something went wrong processing your return request.": "Entschuldigung, bei der Bearbeitung deines R\u00fccksendeantrag ist etwas schiefgelaufen.",
    "Apply": "Sichern",
    "As early as possible": "So schnell wie m\u00f6glich",
    "Available sizes": "Verf\u00fcgbare Gr\u00f6\u00dfen",
    "Back": "Zur\u00fcck",
    "Bank": "Bank",
    "Be inspired": "Lass dich inspirieren",
    "Benefits of an account": "Vorteile eines Kontos",
    "Best regards, {store}": "Dein {store} TEAM",
    "Billing address": "Rechnungsadresse",
    "Billing address is different from the address above": "Rechnungsadresse unterscheidet sich von der o.g. Adresse",
    "Billing- and/or shipping address is not valid": "\u00dcberpr\u00fcfen Sie Ihre Adressdaten",
    "Birthday": "Geburtstag",
    "By": "von",
    "By initiating a return, you agree to our return policy.": "Mit der Beauftragung deiner R\u00fccksendung stimmst du unserer R\u00fcckgaberichtlinie zu.",
    "By {name} on {date}": "Von {name} am {date}",
    "Can't be returned": "Kann nicht zur\u00fcckgesendet werden",
    "Cancel": "Abbrechen",
    "Cart": "Wagen",
    "Categories": "Kategorien",
    "Change delivery address": "Lieferadresse \u00e4ndern",
    "Change password": "Passwort \u00e4ndern",
    "Check if all players meet the required options.": "\u00dcberpr\u00fcfen Sie, ob alle Spieler die erforderlichen Optionen erf\u00fcllen.",
    "Checkout": "Auschecken",
    "Choose a delivery method to proceed to the next step": "W\u00e4hle eine Versand-Option, um mit dem n\u00e4chsten Schritt fortzufahren",
    "Choose a payment method to complete your order": "W\u00e4hle eine Zahlart um deine Bestellung abzuschlie\u00dfen",
    "Choose return reason": "R\u00fccksendegrund ausw\u00e4hlen",
    "Choose your": "W\u00e4hle deine",
    "Choose your FREE product": "W\u00e4hle deinen GRATIS Artikel",
    "Choose your bank": "W\u00e4hle deine Bank",
    "Choose your color": "W\u00e4hle deine Farbe",
    "Choose your country": "W\u00e4hle dein Land",
    "Choose your credit card": "W\u00e4hle deine Kreditkarte",
    "Choose your delivery time:": "W\u00e4hlen Sie Ihren Liefertag:",
    "Choose your personalisation options": "W\u00e4hle deine Bedruckungs-Optionen",
    "Choose your size": "W\u00e4hle deine Gr\u00f6\u00dfe",
    "Choose your stringing personalisation options": "W\u00e4hle deine Bespannung",
    "Choose your {option}": "W\u00e4hle deine {option}",
    "Choose {option}": "W\u00e4hle deine {option}",
    "City": "Stadt",
    "Click here to automatically retrieve your address": "Klick hier, um deine Adresse automatische einzuf\u00fcgen",
    "Click here to enter the address manually.": "Klick hier um deine Adresse manuell einzugeben.",
    "Click here to follow your order": "Hier klicken, um deine Bestellung zu verfolgen",
    "Click {url} to continue shopping": "Klick {url} um weiter einzukaufen",
    "Closed": "Geschlossen",
    "Color": "Farbe",
    "Company": "Firma",
    "Compare": "Vergleichen",
    "Compare - {products}": "Vergleichen - {products}",
    "Compare products": "Produkte vergleichen",
    "Compare {productCount} products": "Keine Produkte | 1 Produkt | {productCount} Produkte",
    "Complaint is a required field.": "Reklamation ist ein Pflichtfeld.",
    "Complete order": "Kaufen",
    "Complete!": "Fertig!",
    "Conditions": "Bedingungen",
    "Confirm new password": "Neues Passwort best\u00e4tigen",
    "Confirm password": "Passwort best\u00e4tigen",
    "Contact details": "Kontakt-Informationen",
    "Contact us": "Kontaktiere unseren Kundenservice",
    "Continue": "Weiter",
    "Continue shopping": "Weiter einkaufen",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "Das Guthaben f\u00fcr den angegebenen Gutscheincode konnte nicht erfasst werden. Bitte versuche es sp\u00e4ter noch einmal. Oder kontaktiere den Kundenservice.",
    "Country": "Land",
    "Create account": "Neu registrieren",
    "Create an account": "Pers\u00f6nliches Konto erstellen",
    "Create an account for your next visit!": "Erstelle ein Konto f\u00fcr deinen n\u00e4chsten Besuch!",
    "Created by": "Realisierung Webshop",
    "Credit card": "Kreditkarte",
    "Current password": "Aktuelles Passwort",
    "DD": "TT",
    "Date": "Datum",
    "Date of birth": "Geburtsdatum",
    "Default billing address": "Standard Rechnungsadresse",
    "Default shipping address": "Standard Lieferadresse",
    "Delete account": "Konto l\u00f6schen",
    "Delivery costs": "Versandkosten",
    "Desired delivery day": "Gew\u00fcnschter Liefertermin",
    "Did you mean {email}?": "Meintest du {email}?",
    "Discount": "Ersparnis gegen\u00fcber UVP:",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Hast du bereits ein Konto bei uns? Melde dich an {action} so dass wir deine Daten \u00fcbernehmen k\u00f6nnen.",
    "Do you have a comment for this return?": "Hast Du eine Bemerkung zu der R\u00fccksendung?",
    "Do you have any questions?": "Hast du noch Fragen?",
    "Do you receive our newsletter?": "Erh\u00e4ltst Du unseren Newsletter?",
    "Do you want to merge your old and new shopping basket?": "Willst du deinen alten und neuen Warenkorb zusammenf\u00fchren?",
    "Download invoice": "Download Rechnung",
    "Download my invoice": "Meine Rechnung herunterladen",
    "Download retour label": "R\u00fccksendeetikett herunterladen",
    "Download return label": "R\u00fccksendeetikett herunterladen",
    "E-mail addresses do not match.": "E-Mail-Adressen stimmen nicht \u00fcberein.",
    "Edit": "\u00e4ndern",
    "Edit address": "Adresse \u00e4ndern",
    "Edit personal settings": "Pers\u00f6nliche Einstellungen \u00e4ndern",
    "Email address": "Email-Adresse",
    "Enter a correct value": "Gebe einen korrekten Wert ein",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Gib deine Gutscheinnummer und deine E-Mail-Adresse ein, ein neuer Geschenkgutschein wird an diese E-Mail geschickt.",
    "Enter your name": "Dein Name",
    "Enter your postal code": "Gebe deine Postleitzahl ein",
    "Expected delivery: {date}": "Voraussichtliche Lieferzeit: {date} Werktage",
    "Extra Addresses": "Zus\u00e4tzliche Adressen",
    "Featured": "Empfehlung",
    "Female": "weiblich",
    "Filter": "Filter",
    "Finalize return": "R\u00fccksendung abschlie\u00dfen",
    "First name": "Vorname",
    "Forgot password": "Passwort vergessen?",
    "Forgot password?": "Passwort vergessen?",
    "Free": "Gratis",
    "Friday": "Freitag",
    "Gewicht": "Besaitungs-H\u00e4rte",
    "Giftcard": "Geschenkgutschein",
    "Go to cart": "Zum Warenkorb",
    "Grand Total": "Gesamtsumme",
    "Grand total": "Gesamtsumme",
    "House no.": "Hausnr.",
    "House number": "Hausnr.",
    "How does it work?": "Wie funktioniert das?",
    "I accept the Riverty {url} *": "Ich akzeptiere die Riverty {url} *",
    "I have a discount code": "Ich habe einen Rabatt-Code",
    "I want to add a comment": "Eine Bemerkung abgeben",
    "I want to return my products": "Ich m\u00f6chte meine Produkte zur\u00fccksenden",
    "I would like to:": "Ich m\u00f6chte:",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Wenn ein Kundenkonto unter {email} gefunden wurde, haben wir dir dorthin eine E-Mail mit einem Link gesendet, um dein Passwort zur\u00fcckzusetzen. Bitte \u00fcberpr\u00fcfe ggfs. auch Deinen Spam-Ordner.",
    "If you don't already have an account, you can register one {here}": "Falls du kein Kundenkonto hast kannst du dich {here} registrieren",
    "Instructions": "Anweisungen",
    "Invalid address. Are you sure it is correct?": "Ung\u00fcltige Adresse. Bist du sicher, dass sie korrekt ist?",
    "Invalid re-captcha.": "Ung\u00fcltiges re-captcha.",
    "Item": "Artikel",
    "Items ordered": "Bestellte Artikel",
    "Last name": "Nachname",
    "Latest releases": "Neuste",
    "Less options": "weniger",
    "Loading shopping cart items...": "Dein Einkauf wird geladen...",
    "Loading totals summary...": "Einen Moment Geduld...",
    "Log in": "Anmelden",
    "Login": "Anmelden",
    "Login with your account": "Anmelden mit deinem Konto",
    "Logout": "Abmelden",
    "MM": "MM",
    "Male": "m\u00e4nnlich",
    "Max {count} characters": "Max {count} Zeichen",
    "Message": "Nachricht",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "Die Mindestl\u00e4nge dieses Feldes muss gleich oder gr\u00f6\u00dfer als {length} Zeichen sein. F\u00fchrende und nachfolgende Leerzeichen werden ignoriert.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "Das Passwort muss mindestens {count} verschiedene Zeichens\u00e4tze enthalten. Zeichens\u00e4tze: Kleinbuchstaben, Gro\u00dfbuchstaben, Ziffern, Sonderzeichen.",
    "Monday": "Montag",
    "More": "Mehr",
    "More options": "mehr",
    "More results for {inputQuery}": "Weitere Ergebnisse f\u00fcr {inputQuery}",
    "Mrs.": "Frau.",
    "My account": "Mein Kundenkonto",
    "My orders": "Meine Bestellungen",
    "My returns": "Meine R\u00fccksendungen",
    "My reviews": "Meine Bewertungen",
    "My wishlist": "Meine Wunschliste",
    "Name": "Name",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "New password": "Neues Passwort",
    "Newsletter": "Newsletter",
    "Newsletters": "Newsletter-Abos",
    "Next": "N\u00e4chster",
    "Nice to add": "Passend hinzuf\u00fcgen",
    "No addresses available": "Keine Adresse vorhanden",
    "No default billing address available": "Keine Strandard-Rechnungsadresse vorhanden",
    "No default shipping address available": "Keine Standard-Lieferadresse vorhanden",
    "No image chosen": "Kein Bild ausgew\u00e4hlt",
    "No order found with order id {orderId}": "Keine Bestellung mit der Bestellnummer {orderId} gefunden",
    "No products": "Keine Produkte",
    "No products were found in this category.": "Keine Artikel wurden in dieser Kategorie gefunden.",
    "No results found for {inputQuery}": "Keine Ergebnisse gefunden f\u00fcr {inputQuery}",
    "No. Only continue with newly added items": "Nein. Nur mit neu hinzugef\u00fcgten Artikeln fortfahren.",
    "Not all sizes are selected.": "Nicht alle Gr\u00f6\u00dfen sind ausgew\u00e4hlt.",
    "Notify me when my size is back in stock": "Benachrichtigt mich, wenn meine Gr\u00f6\u00dfe wieder auf Lager ist",
    "One or more products are out of stock.": "Ein oder mehrere Artikel sind leider nicht vorr\u00e4tig",
    "Only {qty} available!": "Nur {qty} verf\u00fcgbar!",
    "Oops, something went wrong": "Hoppla, etwas ist schiefgelaufen",
    "Opening hours": "\u00d6ffnungszeiten",
    "Order": "Bestellung",
    "Order #": "Bestellung #",
    "Order {increment}": "Bestellung {increment}",
    "Order Date:": "Bestelldatum:",
    "Order comments": "Track & Trace zu deiner Bestellung",
    "Order for your team": "Bestellen Sie f\u00fcr Ihr Team",
    "Order number": "Bestellnr.",
    "Order placed on": "Bestellung aufgegeben am",
    "Order this product for your entire team!": "Bestellen Sie dieses Produkt f\u00fcr Ihr gesamtes Team!",
    "Order this set for your entire team!": "Bestellen Sie dieses Set f\u00fcr Ihr gesamtes Team!",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Bis 14:00 bestellt, <span>am gleichen Tag Mo.-Fr. versandkostenfrei versendet</span>",
    "Ordered items": "Bestellte Artikel",
    "Other sports": "Andere Sportarten",
    "Out of stock notification": "Ausverkauft-Meldung",
    "Overview of your order": "\u00dcbersicht zu deiner Bestellung",
    "Password": "Passwort",
    "Pay safe": "Sicher bezahlen",
    "Payment": "Bezahlung",
    "Payment method": "Zahlart",
    "Payment methods": "Zahlarten",
    "Personal settings": "Pers\u00f6nliche Einstellungen",
    "Personalisation Title": "Personifiera din produkt",
    "Personalize product": "Bedrucke Artikel",
    "Personalize product with name and/or number": "Bedrucke den Artikel mit Name und/oder Nummer!",
    "Personalize product with stringing": "Bespann-Service",
    "Personalize product with stringing and/or weight": "Konfiguriere deinen Schl\u00e4ger mit der Saite deiner Wahl sowie dem gew\u00fcnschten Zuggewicht",
    "Phone number": "Telefonnummer",
    "Pick up your order at:": "Hier kannst du deine Bestellung abholen:",
    "Pickup point": "Packstation",
    "Player": "Spieler",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Kontaktiere bitte den Kundenservice unter kundenservice@passasports.com oder rufe uns unter +49-(0)221-82829909 an.",
    "Please enter a valid code": "Der Gutscheincode ist nicht g\u00fcltig. \u00dcberpr\u00fcfe den Code und versuche es erneut.",
    "Please enter a valid quantity.": "Bitte geben Sie eine g\u00fcltige Menge ein.",
    "Please provide a valid email address.": "Bitte geben Sie eine g\u00fcltige E-Mail-Adresse ein.",
    "Please select a size.": "Bitte w\u00e4hlen Sie eine Gr\u00f6\u00dfe.",
    "Please wait, we are loading the stores ...": "Einen Moment Geduld, wir laden alle DHL Packstationen ...",
    "Please wait, your return request is being processed.": "Bitte warte einen Moment, dein R\u00fccksendeantrag wird verarbeitet. Das kann einige Sekunden dauern.",
    "Postal code": "PLZ",
    "Postal code + house number": "PLZ + Hausnr.",
    "Prefix": "Anrede",
    "Previous": "Vorheriger",
    "Previous step": "Schritt zur\u00fcck",
    "Previously viewed by you": "Zuvor von dir angeschaut",
    "Price": "Preis",
    "Price (Ascending)": "Preis (tief-hoch)",
    "Price (Descending)": "Preis (hoch-tief)",
    "Print return ticket": "R\u00fccksendeschein ausdrucken",
    "Product": "Artikel",
    "Product Description": "Produktbeschreibung",
    "Product out of stock": "Artikel ist ausverkauft",
    "Products by this post": "Produkte in diesem Beitrag",
    "Qty": "Anzahl",
    "Quantity": "Anzahl",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Bewertung von <span>&rdquo;{name}&ldquo;</span>",
    "Read more": "Mehr lesen",
    "Read our return policy": "Lesen Sie unsere R\u00fcckgaberichtlinie",
    "Recent Blog Posts": "Aktuelle Blog-Beitr\u00e4ge",
    "Recent orders": "Deine letzten Bestellungen",
    "Recommended": "Empfohlen",
    "Redeem giftcard": " Geschenkgutschein einl\u00f6sen",
    "Related Products": "Dazu passende Produkte",
    "Remove": "L\u00f6schen",
    "Remove from cart": "Aus dem Warenkorb entfernen",
    "Remove product": "Produkt entfernen",
    "Repeat email address": "Wiederholen Sie Ihre E-Mail-Adresse",
    "Request password": "Neues Passwort erstellen",
    "Request return": "R\u00fccksendung beantragen",
    "Reset password": "Passwort \u00e4ndern",
    "Return": "R\u00fccksendung",
    "Return my products": "Meine Produkte zur\u00fccksenden",
    "Returns": "R\u00fccksendungen",
    "Reviews": "Bewertungen",
    "SKU": "Art.Nr.",
    "Saturday": "Samstag",
    "Save": "Speichern",
    "Save address": "Adresse speichern",
    "Search": "Suchen",
    "Search for...": "Suche nach...",
    "Search results": "Suchergebnisse",
    "Search results for &ldquo;{query}&rdquo;": "Suchergebnisse f\u00fcr &ldquo;{query}&rdquo;",
    "Search your orders": "Suche deine Bestellung",
    "Select a country": "W\u00e4hle ein Land",
    "Select a {carrier} pick-up point": "W\u00e4hle eine {carrier} DHL Packstation",
    "Select an option": "W\u00e4hle eine Option",
    "Select one of the required options.": "W\u00e4hle eine der notwendigen Optionen.",
    "Select your address": "W\u00e4hle deine Adresse",
    "Send": "Senden",
    "Ship to": "Versendet an",
    "Shipping": "Versand",
    "Shipping address": "Lieferadresse",
    "Shipping method": "Versand-Option",
    "Shipping methods": "Versand-Optionen",
    "Shopping cart": "Warenkorb",
    "Short description of your review": "Kurze Titel Deiner Bewertung",
    "Show differences": "Unterschiede anzeigen",
    "Show more": "Mehr anzeigen",
    "Show results": "Ergebnisse anzeigen",
    "Show {totalItems} results": "Keine Ergebnisse | Zeige 1 Ergebnis | Zeige {totalItems} Ergebnisse",
    "Sign me up for the newsletter": "F\u00fcr den Newsletter bitte anmelden",
    "Sign up": "Anmeldung",
    "Sir.": "Herr.",
    "Size": "Gr\u00f6\u00dfe",
    "Size Advice": "Gr\u00f6\u00dfenempfehlung",
    "Size Chart": "Gr\u00f6\u00dfentabelle",
    "Sizes": "Gr\u00f6\u00dfe",
    "Snaar": "Saite",
    "Sold out!": "Der Artikel ist leider ausverkauft",
    "Sold out! Will be expected at <span>{date}</span>.": "Derzeit ausverkauft! Artikel wird verschickt am <span>{date}</span>.",
    "Some items in your shopping cart are out of stock. {action}": "Einige Artikel in deinem Warenkorb sind z.Zt. nicht auf Lager. {action}",
    "Something went wrong while restoring your password": "Diese Email-Adresse haben wir nicht gefunden. Bitte verwende eine bereits registrierte Email-Adresse oder registriere Dich mit dieser Email einfach neu!",
    "Something went wrong, contact us for help.": "Etwas ist schiefgelaufen, kontaktiere uns f\u00fcr Hilfe.",
    "Something went wrong, please try again later": "Etwas ist schiefgelaufen, bitte versuche es ggfs. sp\u00e4ter noch einmal",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Etwas ist schiefgelaufen, die Zahlung konnte nicht durchgef\u00fchrt werden. Bitte versuche es nochmal oder w\u00e4hle eine andere Zahlart.",
    "Sorry we're down for maintenance": "Entschuldigung, wegen Wartungsarbeiten ist die Seite aktuell nicht erreichbar.",
    "Sort by": "Sortieren nach",
    "Specifications": "Spezifikationen",
    "Status": "Status",
    "Store": "Laden",
    "Street": "Stra\u00dfe",
    "Submit": "Abschicken",
    "Submit complaint": "Reklamation anmelden",
    "Submit return": "R\u00fccksendung anmelden",
    "Submit review": "Bewertung absenden",
    "Subscribe": "Anmeldung",
    "Subscribe to our newsletter": "Melde dich f\u00fcr unseren Newsletter an",
    "Subtotal": "Zwischensumme",
    "Successfully created an account, you can log in with your new credentials": "Du hast erfolgreich ein Kundenkonto erstellt und kannst dich jetzt mit deinen Zugangsdaten einloggen",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Neuer Geschenkgutschein erfolgreich angefordert. Bitte pr\u00fcfe deine E-Mail auf den neuen Gutscheincode.",
    "Successfully saved address": "Adresse gespeichert",
    "Suggested price": "UVP",
    "Sunday": "Sonntag",
    "Swipe for more products": "Wischen f\u00fcr mehr Produkte",
    "Tags": "Tags",
    "Telephone": "Telefonnr.",
    "Thank you for your order!": "Vielen Dank f\u00fcr deine Bestellung!",
    "Thank you for your order, {name}": "Vielen Dank f\u00fcr deine Bestellung, {name}",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Vielen Dank f\u00fcr deine Anfrage, du wirst per Mail von uns benachrichtigt, wenn deine Gr\u00f6\u00dfe wieder auf Lager ist!",
    "Thank you. We have received your complaint!": "Wir danken Ihnen. Wir haben deine Reklamation erhalten!",
    "Thanks": "Vielen Dank",
    "Thanks for placing a review!": "Vielen Dank f\u00fcr deine Bewertung!",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Die Kombination aus E-Mail-Adresse und Passwort ist bei uns unbekannt.",
    "The benefits of an account:": "Die Vorteile eines Kontos:",
    "The cache could not be cleared": "Der Cache konnte nicht geleert werden",
    "The cache of {cache} has been emptied": "Der Cache von {cache} wurde geleert",
    "The coupon code could not be cancelled, please try again later.": "Der Gutscheincode konnte nicht storniert werden, bitte versuchen Sie es sp\u00e4ter erneut.",
    "The coupon code isn't valid. Verify the code and try again.": "Dieser Rabattcode ist nicht bekannt. Hast du vielleicht einen Geschenkgutschein/Giftcard? Dann gehe bitte zu den Zahlungsmethoden und w\u00e4hle dort die Option \u201eGiftcard\" aus.",
    "The day of birth is required.": "Der Geburtstagstag ist erforderlich.",
    "The day of birth must be a number.": "Der Geburtstagstag muss eine Zahl sein.",
    "The day of birth must be at least 1.": "Der Geburtstagstag muss mindestens 1 sein.",
    "The email address is required.": "Die E-Mail-Adresse ist erforderlich.",
    "The email address must not exceed 255 characters.": "Die E-Mail-Adresse darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The first name is required.": "Der Vorname ist erforderlich.",
    "The first name must be a valid string.": "Der Vorname muss eine g\u00fcltige Zeichenkette sein.",
    "The first name must be at least 2 characters long.": "Der Vorname muss mindestens 2 Zeichen lang sein.",
    "The first name must not exceed 255 characters.": "Der Vorname darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The gender must be either Male or Female.": "Das Geschlecht muss entweder m\u00e4nnlich oder weiblich sein.",
    "The last name is required.": "Der Nachname ist erforderlich.",
    "The last name must be a valid string.": "Der Nachname muss eine g\u00fcltige Zeichenkette sein.",
    "The last name must be at least 2 characters long.": "Der Nachname muss mindestens 2 Zeichen lang sein.",
    "The last name must not exceed 255 characters.": "Der Nachname darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The month of birth is required.": "Der Geburtsmonat ist erforderlich.",
    "The month of birth must be a number.": "Der Geburtsmonat muss eine Zahl sein.",
    "The month of birth must be at least 1.": "Der Geburtsmonat muss mindestens 1 sein.",
    "The most used payment method in The Netherlands": "Die meist genutzte Zahlart in den Niederlanden",
    "The password confirmation does not match.": "Die Passwortbest\u00e4tigung stimmt nicht \u00fcberein.",
    "The password is required.": "Das Passwort ist erforderlich.",
    "The payment is canceled, the order is not paid": "Die Zahlung wurde abgebrochen, die Bestellung ist nicht bezahlt",
    "The payment provider rejected the payment request, please use another payment method.": "Der Zahlungsanbieter hat die Zahlungsanfrage abgelehnt, bitte verwenden Sie eine andere Zahlungsmethode.",
    "The quantity exceeds the available stock.": "Die Menge \u00fcbersteigt den verf\u00fcgbaren Lagerbestand.",
    "The year of birth is required.": "Das Geburtsjahr ist erforderlich.",
    "The year of birth must be a number.": "Das Geburtsjahr muss eine Zahl sein.",
    "The year of birth must be at least 4 digits long.": "Das Geburtsjahr muss mindestens 4 Ziffern lang sein.",
    "There are no products in your wishlist": "Deine Wunschliste ist noch leer",
    "There are no specification found to compare.": "Es wurden keine Spezifikationen gefunden, die verglichen werden k\u00f6nnen.",
    "This is a secure connection": "Dies ist eine sichere Verbindung",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "diese Seite bietet dir einen \u00dcberblick \u00fcber deine letzten Aktivit\u00e4ten. Hier kannst du auch Bestellungen einsehen und Kontoinformationen aktualisieren!",
    "This product has custom personalisation options.": "Dieses Produkt hat individuelle Personalisierungsoptionen.",
    "This product is in stock": "Dieses Produkt ist auf Lager",
    "This quantity cannot be changed.": "Diese Menge kann nicht ge\u00e4ndert werden.",
    "Thursday": "Donnerstag",
    "Tierprices": "Staffelpreise",
    "To Kiyoh reviews page": "Geben Sie Ihre Meinung ab",
    "To checkout": "Zur Kasse",
    "To shipping": "Zum Versand",
    "To shopping cart": "Zum Warenkorb",
    "Total": "Gesamt",
    "Track my order": "Meine Bestellung verfolgen",
    "Tuesday": "Dienstag",
    "Unable to save review": "Bewertung kann nicht gesendet werden",
    "Upload image": "Bild hochladen",
    "Used coupon": "Gebrauchter Gutscheincode",
    "View order": "Bestellung anschauen",
    "View product": "Produkt ansehen",
    "View products.": "Artikel anschauen.",
    "Visit our <a href=\"/contact\"> customer service </a>": "Kontaktiere unseren <a href=\"/contact\">Kundenservice</a>",
    "We expect to deliver on": "Vorrausichtlicher Versandtag",
    "We expect to deliver your complete order on {date}": "Wir erwarten, dass wir Ihre gesamte Bestellung am {date} liefern",
    "We have found the following address": "Wir haben folgende Adresse gefunden",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "Wir empfehlen Ihnen, <strong>eine Nummer gr\u00f6\u00dfer</strong> zu bestellen, als Sie normalerweise tragen.",
    "We still have these colors for size {size} in stock!": "Wir haben diese Farbe f\u00fcr die Gr\u00f6\u00dfe {size} noch auf Lager! | Wir haben diese Farben f\u00fcr Gr\u00f6\u00dfe {size} noch auf Lager!",
    "We will send a confirmation by e-mail to": "Bestellbest\u00e4tigung versenden wir an",
    "We'll be back shortly": "Wir sind in K\u00fcrze zur\u00fcck!",
    "Wednesday": "Mittwoch",
    "Welcome {name}": "Willkommen {name}",
    "What do our customers say": "Was unsere Kunden sagen",
    "Why?": "Warum?",
    "Will be expected at <span>{date}</span>.": "Artikel wird verschickt am <span>{date}</span>.",
    "Wishlist": "Meine Wunschliste",
    "Without extra fees": "Ohne zus\u00e4tzl. Kosten",
    "Write a review": "Schreibe eine Bewertung",
    "Write your comment": "Formuliere deine Bemerkung",
    "Written by {name} on {date}": "Von {name} am {date}",
    "YYYY": "JJJJ",
    "Yes, I want to permanently delete my account": "Ja, ich m\u00f6chte mein Konto dauerhaft l\u00f6schen",
    "Yes, I\u2019ve double-checked that my email address is correct.": "Ja, ich habe \u00fcberpr\u00fcft, dass meine E-Mail-Adresse korrekt ist.",
    "Yes. Merge and continue.": "Ja. Zusammenf\u00fchren und fortfahren.",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "Du hattest bereits Artikel in deinem Warenkorb. M\u00f6chtest du sie mit den jetzt hinzugef\u00fcgten Artikeln zusammenf\u00fchren?",
    "You already have an account with us. Please log in or continue without an account.": "Du hast bereits ein Konto bei uns. Bitte melde dich an oder setze den Vorgang ohne ein Kundenkonto fort.",
    "You are now logged out and will return to our home page within 5 seconds.": "Du hast dich nun abgemeldet und wirst innerhalb von 5 Sekunden zur Startseite geleitet.",
    "You are now signed out": "Du hast dich nun abgemeldet",
    "You are subscribed to our newsletter": "Du hast dich f\u00fcr unseren Newsletter angemeldet",
    "You can compare up to {count} products": "Sie k\u00f6nnen bis zu {count} Produkte vergleichen",
    "You can create an account after you\u2019ve purchased the order.": "Du kannst nach Abschluss des Bestellvorgangs ein Kundenkonto erstellen.",
    "You can only compare up to {products} products at the same time.": "Sie k\u00f6nnen maximal {products} Produkte gleichzeitig vergleichen.",
    "You can pick up your order at": "Lieferadresse Deiner Bestellung",
    "You don't seem to be logged in, please login to enable stock notifications.": "Du scheinst nicht angemeldet zu sein. Bitte melde dich an, um Lager-Benachrichtigungen zu aktivieren.",
    "You have an account. Would you like to {action}?": "Du hast ein Konto. M\u00f6chtest du dich {action}?",
    "You have no product(s) in your shopping cart.": "Im Warenkorb befinden sich keine Artikel",
    "You have not placed any orders yet": "Es liegen noch keine Bestellungen vor",
    "You have not reviewed any products yet": "Du hast noch keine Produktbewertungen abgegeben",
    "You may also like this": "Das k\u00f6nnte dir auch gefallen",
    "You will receive a confirmation via": "Du erh\u00e4ltst eine Best\u00e4tigung per",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Du solltest innerhalb von 15 Minuten per Email eine Bestellbest\u00e4tigung mit allen Details erhalten. Falls du keine Best\u00e4tigungs-Email erhalten hast, \u00fcberpr\u00fcfe bitte ggfs. deinen Spam-Ordner. Bei allen Fragen zum Status deiner Bestellung, kontaktiere bitte den Kundenservice.",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "Du erh\u00e4ltst in K\u00fcrze eine E-Mail mit den Details deiner Bestellung. Bei Fragen zu deiner Bestellung, kontaktiere bitte unseren <a href=\"/contact\">Kundenservice</a>!",
    "Your applied coupon code is {couponCodeInput}.": "Dein Rabatt-Code lautet {couponCodeInput}.",
    "Your compare list is empty.": "Ihre Vergleichsliste ist leer.",
    "Your email address": "Deine E-Mail-Adresse",
    "Your last chosen payment method": "deine zuletzt verwendete Zahlungsmethode",
    "Your message has been sent.": "Deine Nachricht wurde versendet.",
    "Your order has been placed successfully.": "Wir haben deine Bestellung erhalten.",
    "Your order number is: <strong>#{orderid}</strong>": "Deine Bestellnummer ist: <strong>#{orderid}</strong>",
    "Your order will be delivered to": "Deine Bestellung wird versendet an",
    "Your ordernumber is: #{order_id}.": "Deine Bestellnummer ist: #{order_id}.",
    "Your password has been updated": "Dein Passwort wurde aktualisiert",
    "Your rating": "Deine Bewertung",
    "Your recommended size is currently not available!": "Die von Ihnen empfohlene Gr\u00f6\u00dfe ist derzeit nicht verf\u00fcgbar!",
    "contact_form.subject": "Kontaktformular {store}Name",
    "description": "Beschreibung",
    "here": "hier",
    "in {label}": "in {label}",
    "login": "anmelden",
    "name": "Name",
    "new": "Neu",
    "ordered": "bestellt",
    "personal ID": "DHL Postnummer (6-10 Zahlen)",
    "recommended": "Favorit",
    "review": "Deine Bewertung",
    "subscribe": "Melde dich an",
    "terms of payment": "Zahlungsbedingungen",
    "to": "bis",
    "tomorrow": "morgen",
    "validation.email": "Ung\u00fcltige Email-Adresse in Feld '{attribute}'",
    "validation.required": "Feld '{attribute}' ist ein Pflichtfeld",
    "{count} results": "Keine Ergebnisse | 1 Ergebnis | {count} Ergebnisse",
    "{distance} kilometer": "{distance} Kilometer",
    "{distance} meter": "{distance} Meter",
    "{name} has been added to your cart.": "{name} wurde deinem Warenkorb hinzugef\u00fcgt.",
    "{qty} pieces for {price} each": "{qty} St\u00fcck f\u00fcr jeweils {price}"
}