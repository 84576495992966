export default {
    " - {stock} in stock": " - {stock} in stock",
    "{count} ratings": "{0} 0 ratings|{1} {count} rating|[2,*] {count} ratings",
    "{shop} scores a {average} based on {total} reviews": "{shop} scores a {average} based on {total} reviews",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> based on <span>{count}</span> reviews",
    "Accept terms and conditions on return submit note": "Accept terms and conditions on return submit note",
    "Account overview": "Account overview",
    "Active filters": "Active filters",
    "Add new address": "Add new address",
    "Add player | Add players": "Add player | Add players",
    "Add product": "Add product",
    "Add set to cart": "Add set to cart",
    "Add to cart": "Add to cart",
    "Add.": "Add.",
    "Added to cart": "Added to cart",
    "Addition": "Addition",
    "Address": "Address",
    "Addresses": "Addresses",
    "All {label}": "All {label}",
    "All about the {name}": "All about the {name}",
    "All about this product": "All about this product",
    "All fields must be filled": "All fields must be filled.",
    "Already returned": "Already returned",
    "Alternate Products": "Alternate Products",
    "An error occurred during the payment process, please try again or use another payment method.": "An error occurred during the payment process, please try again or use another payment method.",
    "An error occurred while fetching the order. Please try again later.": "An error occurred while fetching the order. Please try again later.",
    "An error occurred while fetching the return label. Please try again later.": "An error occurred while fetching the return label. Please try again later.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "An error occurred while retrieving the order. Please check your details or try again later.",
    "Apologies, something went wrong processing your return request.": "Apologies, something went wrong processing your return request.",
    "Apply": "Apply",
    "As early as possible": "As early as possible",
    "Available sizes": "Available sizes",
    "Back": "Back",
    "Bank": "Bank",
    "Be inspired": "Be inspired",
    "Benefits of an account": "Benefits of an account",
    "Best regards, {store}": "Best regards, {store}",
    "Billing address": "Billing address",
    "Billing address is different from the address above": "Billing address is different from the address above",
    "Billing- and/or shipping address is not valid": "Please check your address information",
    "Birthday": "Birthday",
    "By": "By",
    "By initiating a return, you agree to our return policy.": "By initiating a return, you agree to our return policy.",
    "By {name} on {date}": "By {name} on {date}",
    "Can't be returned": "Can't be returned",
    "Cancel": "Cancel",
    "Categories": "Categories",
    "Change delivery address": "Change delivery address",
    "Change password": "Change password",
    "Check if all players meet the required options.": "Check if all players meet the required options.",
    "Choose a delivery method to proceed to the next step": "Choose a delivery method to proceed to the next step",
    "Choose a payment method to complete your order": "Choose a payment method to complete your order",
    "Choose return reason": "Choose return reason",
    "Choose your": "Choose your",
    "Choose your FREE product": "Choose your FREE product",
    "Choose your bank": "Choose your bank",
    "Choose your color": "Choose your color",
    "Choose your country": "Choose your country",
    "Choose your credit card": "Choose your credit card",
    "Choose your personalisation options": "Choose your personalisation options",
    "Choose your size": "Choose your size",
    "Choose your stringing personalisation options": "Stringing",
    "Choose your {option}": "Choose your {option}",
    "Choose {option}": "Choose your {option}",
    "City": "City",
    "Click here to automatically retrieve your address": "Click here to automatically retrieve your address",
    "Click here to enter the address manually.": "Click here to enter the address manually.",
    "Click here to follow your order": "Click here to track your order",
    "Click {url} to continue shopping": "Click {url} to continue shopping",
    "Closed": "Closed",
    "Color": "Color",
    "Company": "Company",
    "Compare": "Compare",
    "Compare - {products}": "Compare - {products}",
    "Compare products": "Compare products",
    "Compare {productCount} products": "No products | Compare 1 product | Compare {productCount} products",
    "Complaint is a required field.": "Complaint is a required field.",
    "Complete order": "Complete order",
    "Complete!": "Complete!",
    "Conditions": "Conditions",
    "Confirm new password": "Confirm new password",
    "Confirm password": "Confirm password",
    "Contact details": "Contact details",
    "Contact us": "Contact us",
    "Continue": "Continue",
    "Continue shopping": "Continue shopping",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "Could not collect balance for given giftcard code. Please try again later or contact support.",
    "Country": "Country",
    "Create account": "Create account",
    "Create an account": "Create an account",
    "Create an account for your next visit!": "Create an account for your next visit!",
    "Created by": "Created by",
    "Credit card": "Credit card",
    "Current password": "Current password",
    "DD": "DD",
    "Date": "Date",
    "Date of birth": "Date of birth",
    "Default billing address": "Default billing address",
    "Default shipping address": "Default shipping address",
    "Delivery costs": "Delivery costs",
    "Desired delivery day": "Desired delivery day",
    "Did you mean {email}?": "Did you mean {email}?",
    "Discount": "You save",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Do you already have an account with us? Log in {action} and we will get your data.",
    "Do you have a comment for this return?": "Do you have a comment for this return?",
    "Do you have any questions?": "Do you have any questions?",
    "Do you receive our newsletter?": "Do you receive our newsletter?",
    "Do you want to merge your old and new shopping basket?": "Do you want to merge your old and new shopping basket?",
    "Download invoice": "Download invoice",
    "Download my invoice": "Download my invoice",
    "Download retour label": "Download return label",
    "Download return label": "Download return label",
    "Edit": "Edit",
    "Edit address": "Edit address",
    "Edit personal settings": "Edit personal settings",
    "Email address": "Email address",
    "Enter a correct value": "Enter a correct value",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Enter your giftcard number and email address, a new giftcard will be send to this email.",
    "Enter your name": "Enter your name",
    "Enter your postal code": "Enter your postal code",
    "Expected delivery: {date}": "Will be shipped within {date} business days",
    "Extra Addresses": "Extra Addresses",
    "Featured": "Featured",
    "Female": "Female",
    "Filter": "Filter",
    "Finalize return": "Finalize return",
    "First name": "First name",
    "Forgot password": "Forgot password",
    "Forgot password?": "Forgot password?",
    "Free": "Free",
    "Friday": "Friday",
    "Gewicht": "Tension",
    "Giftcard": "Giftcard",
    "Go to cart": "Go to cart",
    "Grand Total": "Grand Total",
    "Grand total": "Grand total",
    "House no.": "House no.",
    "House number": "House number",
    "How does it work?": "How does it work?",
    "I accept the Riverty {url} *": "I accept the Riverty {url} *",
    "I have a discount code": "I have a discount code",
    "I want to add a comment": "I want to add a comment",
    "I want to return my products": "I want to return my products",
    "I would like to:": "I would like to:",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "If an account is found with {email}, you will receive an email with a link to reset your password.",
    "If you don't already have an account, you can register one {here}": "If you don't already have an account, you can register one {here}",
    "Instructions": "Instructions",
    "Invalid address. Are you sure it is correct?": "Invalid address. Are you sure it is correct?",
    "Invalid re-captcha.": "Invalid re-captcha.",
    "Item": "Item",
    "Items ordered": "Items ordered",
    "Last name": "Last name",
    "Latest releases": "Latest releases",
    "Less options": "Less",
    "Loading shopping cart items...": "Loading shopping cart items...",
    "Loading totals summary...": "Loading...",
    "Log in": "Log in",
    "Login": "Login",
    "Login with your account": "Login with your account",
    "Logout": "Logout",
    "MM": "MM",
    "Male": "Male",
    "Max {count} characters": "Max {count} characters",
    "Message": "Message",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "The minimum length of this field must be equal to or greater than {length} characters. Leading and trailing spaces will be ignored.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "The password must contain at least {count} different character classes. Character classes: Lower Case, Upper Case, Digits, Special Characters.",
    "Monday": "Monday",
    "More": "More",
    "More options": "More",
    "More results for {inputQuery}": "More results for {inputQuery}",
    "Mrs.": "Mrs.",
    "My account": "My account",
    "My orders": "My orders",
    "My returns": "My returns",
    "My reviews": "My reviews",
    "My wishlist": "My wishlist",
    "Name": "Name",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "New password": "New password",
    "Newsletter": "Newsletter",
    "Newsletters": "Newsletters",
    "Next": "Next",
    "Nice to add": "Nice to add",
    "No addresses available": "No addresses available",
    "No default billing address available": "No default billing address available",
    "No default shipping address available": "No default shipping address available",
    "No image chosen": "No image chosen",
    "No order found with order id {orderId}": "No order found with order id {orderId}",
    "No products": "No products",
    "No products were found in this category.": "No products were found in this category.",
    "No results found for {inputQuery}": "No results found for {inputQuery}",
    "No. Only continue with newly added items": "No. Only continue with newly added items",
    "Not all sizes are selected.": "Not all sizes are selected.",
    "Notify me when my size is back in stock": "Notify me when my size is back in stock",
    "One or more products are out of stock.": "One or more products are out of stock.",
    "Only {qty} available!": "Only {qty} available!",
    "Oops, something went wrong": "Oops, something went wrong",
    "Opening hours": "Opening hours",
    "Order": "Order",
    "Order #": "Order #",
    "Order {increment}": "Order {increment}",
    "Order Date:": "Order Date:",
    "Order comments": "Order comments",
    "Order for your team": "Order for your team",
    "Order number": "Order number",
    "Order placed on": "Order placed on",
    "Order this set for your entire team!": "Order this set for your entire team!",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Ordered before 22:00, <span>delivered for free</span> the next day",
    "Ordered items": "Ordered items",
    "Other sports": "Other sports",
    "Out of stock notification": "Out of stock notification",
    "Overview of your order": "Overview of your order",
    "Password": "Password",
    "Pay safe": "Pay safe",
    "Payment": "Payment",
    "Payment method": "Payment method",
    "Payment methods": "Payment methods",
    "Personal settings": "Personal settings",
    "Personalisation Title": "Personalise your product",
    "Personalize product": "Personalize product",
    "Personalize product with name and/or number": "Personalize product with name and/or number",
    "Personalize product with stringing": "String my racket",
    "Personalize product with stringing and/or weight": "Customize racket with stringing and weight",
    "Phone number": "Phone number",
    "Pick up your order at:": "Pick up your order at:",
    "Pickup point": "Pickup point",
    "Player": "Player",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.",
    "Please enter a valid code": "Please enter a valid code",
    "Please provide a valid email address.": "Please provide a valid email address.",
    "Please wait, we are loading the stores ...": "Please wait, we are loading the stores ...",
    "Please wait, your return request is being processed.": "Please wait, your return request is being processed.",
    "Postal code": "Postal code",
    "Postal code + house number": "Postal code + house number",
    "Prefix": "Prefix",
    "Previous": "Previous",
    "Previous step": "Previous step",
    "Previously viewed by you": "Previously viewed by you",
    "Price": "Price",
    "Price (Ascending)": "Price (Ascending)",
    "Price (Descending)": "Price (Descending)",
    "Print return ticket": "Print return ticket",
    "Product": "Product",
    "Product Description": "Product Description",
    "Product out of stock": "Product out of stock",
    "Products by this post": "Products by this post",
    "Qty": "Qty",
    "Quantity": "Quantity",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Rating of <span>&rdquo;{name}&ldquo;</span>",
    "Read more": "Read more",
    "Read our return policy": "Read our return policy",
    "Recent Blog Posts": "Recent Blog Posts",
    "Recent orders": "Recent orders",
    "Redeem giftcard": "Redeem giftcard",
    "Related Products": "Related Products",
    "Remove": "Remove",
    "Remove from cart": "Remove from cart",
    "Remove product": "Remove product",
    "Request password": "Request password",
    "Request return": "Request return",
    "Reset password": "Reset password",
    "Return": "Return",
    "Return my products": "Return my products",
    "Returns": "Returns",
    "Reviews": "Reviews",
    "SKU": "SKU",
    "Saturday": "Saturday",
    "Save": "Save",
    "Save address": "Save address",
    "Search": "Search",
    "Search for...": "Search for...",
    "Search results": "Search results",
    "Search results for &ldquo;{query}&rdquo;": "Search results for &ldquo;{query}&rdquo;",
    "Search your orders": "Search your orders",
    "Select a country": "Select a country",
    "Select a {carrier} pick-up point": "Select a {carrier} pick-up point",
    "Select an option": "Select an option",
    "Select one of the required options.": "Select one of the required options.",
    "Select your address": "Select your address",
    "Send": "Send",
    "Ship to": "Ship to",
    "Shipping": "Shipping",
    "Shipping address": "Shipping address",
    "Shipping method": "Shipping method",
    "Shipping methods": "Shipping methods",
    "Shopping cart": "Shopping cart",
    "Short description of your review": "Short description of your review",
    "Show differences": "Show differences",
    "Show more": "Show more",
    "Show results": "Show results",
    "Show {totalItems} results": "No results | Show 1 result | Show {totalItems} results",
    "Sign me up for the newsletter": "Sign me up for the newsletter",
    "Sign up": "Sign up",
    "Sir.": "Sir.",
    "Size": "Size",
    "Size Advice": "Size Advice",
    "Size Chart": "Size Chart",
    "Sizes": "Sizes",
    "Snaar": "Type of string",
    "Sold out!": "The article is unfortunately sold out",
    "Sold out! Will be expected at <span>{date}</span>.": "Sold out! Will be expected at <span>{date}</span>.",
    "Some items in your shopping cart are out of stock. {action}": "Some items in your shopping cart are out of stock. {action}",
    "Something went wrong while restoring your password": "Something went wrong while restoring your password",
    "Something went wrong, contact us for help.": "Something went wrong, contact us for help.",
    "Something went wrong, please try again later": "Something went wrong, please try again later",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.",
    "Sorry we're down for maintenance": "Sorry we're down for maintenance",
    "Sort by": "Sort by",
    "Specifications": "Specifications",
    "Status": "Status",
    "Street": "Street",
    "Submit": "Submit",
    "Submit complaint": "Submit complaint",
    "Submit return": "Submit return",
    "Submit review": "Submit review",
    "Subscribe": "Subscribe",
    "Subscribe to our newsletter": "Subscribe to our newsletter",
    "Subtotal": "Subtotal",
    "Successfully created an account, you can log in with your new credentials": "Successfully created an account, you can log in with your new credentials",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Successfully requested new giftcard. Please check your email for the new giftcard code.",
    "Suggested price": "RRP",
    "Sunday": "Sunday",
    "Swipe for more products": "Swipe for more products",
    "Tags": "Tags",
    "Telephone": "Telephone",
    "Thank you for your order!": "Thank you for your order!",
    "Thank you for your order, {name}": "Thank you for your order, {name}",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Thank you for your request, you will be notified by mail when the size is back in stock!",
    "Thanks": "Thanks",
    "Thanks for placing a review!": "Thanks for placing a review!",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "This email address and password combination is unknown.",
    "The benefits of an account:": "The benefits of an account:",
    "The cache could not be cleared": "The cache could not be cleared",
    "The cache of has been emptied": "The cache of has been emptied",
    "The coupon code could not be cancelled, please try again later.": "The coupon code could not be cancelled, please try again later.",
    "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
    "The day of birth is required.": "The day of birth is required.",
    "The day of birth must be a number.": "The day of birth must be a number.",
    "The day of birth must be at least 1.": "The day of birth must be at least 1.",
    "The email address is required.": "The email address is required.",
    "The email address must not exceed 255 characters.": "The email address must not exceed 255 characters.",
    "The first name is required.": "The first name is required.",
    "The first name must be a valid string.": "The first name must be a valid string.",
    "The first name must be at least 2 characters long.": "The first name must be at least 2 characters long.",
    "The first name must not exceed 255 characters.": "The first name must not exceed 255 characters.",
    "The gender must be either Male or Female.": "The gender must be either Male or Female.",
    "The last name is required.": "The last name is required.",
    "The last name must be a valid string.": "The last name must be a valid string.",
    "The last name must be at least 2 characters long.": "The last name must be at least 2 characters long.",
    "The last name must not exceed 255 characters.": "The last name must not exceed 255 characters.",
    "The month of birth is required.": "The month of birth is required.",
    "The month of birth must be a number.": "The month of birth must be a number.",
    "The month of birth must be at least 1.": "The month of birth must be at least 1.",
    "The most used payment method in The Netherlands": "The most used payment method in The Netherlands",
    "The password confirmation does not match.": "The password confirmation does not match.",
    "The password is required.": "The password is required.",
    "The payment is canceled, the order is not paid": "The payment is canceled, the order is not paid",
    "The payment provider rejected the payment request, please use another payment method.": "The payment provider rejected the payment request, please use another payment method.",
    "The year of birth is required.": "The year of birth is required.",
    "The year of birth must be a number.": "The year of birth must be a number.",
    "The year of birth must be at least 4 digits long.": "The year of birth must be at least 4 digits long.",
    "There are no products in your wishlist": "There are no products in your wishlist",
    "There are no specification found to compare.": "There are no specification found to compare.",
    "This is a secure connection": "This is a secure connection",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!",
    "This product has custom personalisation options.": "This product has custom personalisation options.",
    "This product is in stock": "This product is in stock",
    "Thursday": "Thursday",
    "Tierprices": "Tierprices",
    "To Kiyoh reviews page": "Give your opinion",
    "To checkout": "To checkout",
    "To shipping": "To shipping",
    "To shopping cart": "To shopping cart",
    "Total": "Total",
    "Track my order": "Track my order",
    "Tuesday": "Tuesday",
    "Unable to save review": "Unable to save review",
    "Upload image": "Upload image",
    "Used coupon": "Used coupon",
    "View order": "View order",
    "View product": "View product",
    "View products.": "View products.",
    "Visit our <a href=\"/contact\"> customer service </a>": "Visit our <a href=\"/contact\"> customer service </a>",
    "We expect to deliver on": "We expect to deliver on",
    "We expect to deliver your complete order on {date}": "We expect to deliver your complete order on {date}",
    "We have found the following address": "We have found the following address",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "We recommend that you order <strong>one size larger</strong> than you normally wear.",
    "We still have these colors for size {size} in stock!": "We still have this color for size {size} in stock! | We still have these colors for size {size} in stock!",
    "We will send a confirmation by e-mail to": "We will send a confirmation by e-mail to",
    "We'll be back shortly": "We'll be back shortly",
    "Wednesday": "Wednesday",
    "Welcome {name}": "Welcome {name}",
    "What do our customers say": "What do our customers say",
    "Why?": "Why?",
    "Will be expected at <span>{date}</span>.": "Will be expected at <span>{date}</span>.",
    "Wishlist": "Wishlist",
    "Without extra fees": "Without extra fees",
    "Write a review": "Write a review",
    "Write your comment": "Write your comment",
    "Written by {name} on {date}": "Written by {name} on {date}",
    "YYYY": "YYYY",
    "Yes. Merge and continue.": "Yes. Merge and continue.",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "You already had items in your shopping basket. Would you like to merge them with the items you have now added?",
    "You already have an account with us. Please log in or continue without an account.": "You already have an account with us. Please log in or continue without an account.",
    "You are now logged out and will return to our home page within 5 seconds.": "You are now logged out and will return to our home page within 5 seconds.",
    "You are now signed out": "You are now signed out",
    "You are subscribed to our newsletter": "You are subscribed to our newsletter",
    "You can compare up to {count} products": "You can compare up to {count} products",
    "You can create an account after you\u2019ve purchased the order.": "You can create an account after you\u2019ve purchased the order.",
    "You can only compare up to {products} products at the same time.": "You can only compare up to {products} products at the same time.",
    "You can pick up your order at": "You can pick up your order at",
    "You don't seem to be logged in, please login to enable stock notifications.": "You don't seem to be logged in, please login to enable stock notifications.",
    "You have an account. Would you like to {action}?": "You have an account. Would you like to {action}?",
    "You have no product(s) in your shopping cart.": "You have no product(s) in your shopping cart.",
    "You have not placed any orders yet": "You have not placed any orders yet",
    "You have not reviewed any products yet": "You have not reviewed any products yet",
    "You may also like this": "You may also like this",
    "You will receive a confirmation via": "You will receive a confirmation via",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!",
    "Your applied coupon code is {couponCodeInput}.": "Your applied coupon code is {couponCodeInput}.",
    "Your compare list is empty.": "Your compare list is empty.",
    "Your email address": "Your email address",
    "Your last chosen payment method": "Your last chosen payment method",
    "Your message has been sent.": "Your message has been sent.",
    "Your order has been placed successfully.": "Your order has been placed successfully.",
    "Your order number is: <strong>#{orderid}</strong>": "Your order number is: <strong>#{orderid}</strong>",
    "Your order will be delivered to": "Your order will be delivered to",
    "Your ordernumber is: #{order_id}.": "Your ordernumber is: #{order_id}.",
    "Your password has been updated": "Your password has been updated",
    "Your rating": "Your rating",
    "Your recommended size is currently not available!": "Your recommended size is currently not available!",
    "contact_form.subject": "{name} has filled in the contact form at {store}Name.",
    "description": "description",
    "here": "here",
    "in {label}": "in {label}",
    "login": "login",
    "name": "name",
    "new": "New",
    "ordered": "ordered",
    "personal ID": "personal ID",
    "recommended": "Our choice",
    "review": "review",
    "subscribe": "subscribe",
    "terms of payment": "terms of payment",
    "to": "to",
    "tomorrow": "tomorrow",
    "validation.email": "Invalid email address for field '{attribute}'",
    "validation.required": "Field '{attribute}' is required",
    "{count} results": "No results | 1 result | {count} results",
    "{distance} kilometer": "{distance} kilometer",
    "{distance} meter": "{distance} meter",
    "{name} has been added to your cart.": "{name} has been added to your cart.",
    "{qty} pieces for {price} each": "{qty} pieces for {price} each"
}